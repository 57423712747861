import React from "react";

import Consultancy from "../images/service-icons/consultancy.png";
import Systems from "../images/service-icons/systems.png";
import Bi from "../images/service-icons/bi.png";
import Button from "./button";

const Services = () => {
  return (
    <div id="#services">
      <div className="md:container md:mx-auto">
        <div className="lg:py-30 md:py-16 pt-5 pb-12">
          <div className="flex flex-col relative bg-secondary-100 lg:py-0 py-10">
            <div className="absolute lg:block hidden h-16 bg-white bottom-0 left-0 right-0"></div>
            <div className="absolute lg:block hidden h-16 bg-white top-0 left-0 right-0"></div>
            <div className="grid lg:grid-cols-12 grid-cols-1 xl:gap-8 items-center  relative">
              <div className="lg:col-span-5 flex flex-col items-start xl:px-18 lg:px-10 md:px-8 px-5 lg:py-32 md:pb-4 pb-10">
                <h2 className="lg:text-display-lg text-display-md font-semibold pb-4">
                  Serviços que oferecemos
                </h2>
                <p className="text-body-md font-normal text-neutral-600 pb-8">
                  Transformamos desafios em oportunidades com soluções tecnológicas personalizadas. Entregamos excelência em cada projeto, garantindo que você atinja seus objetivos com eficiência e sucesso.
                </p>
                <Button label="Fale Conosco" link="/" size="lg" />
              </div>
              <div className="lg:col-span-7 flex md:flex-row flex-col lg:gap-8 gap-6 items-center xl:pr-16 lg:pr-10 lg:pl-0 md:px-8 px-5 relative">
                <div className="flex flex-col basis-1/2 lg:gap-8 gap-6 relative">
                  <div className="bg-white p-8 shadow-xl">
                    <img src={Systems} alt="UI" className="pb-6 w-6" />
                    <p className="text-display-xs font-semibold pb-4">
                      Desenvolvimento de sistemas
                    </p>
                    <p className="text-body-md font-normal text-neutral-600">
                      Sites, aplicativos, e qualquer outro tipo de plataforma. 
                      Adoramos desafios em nosso dia a dia.
                    </p>
                  </div>
                </div>
                <div className="flex flex-col basis-1/2 lg:gap-8 gap-6 relative">
                  <div className="bg-white p-8 shadow-xl">
                    <img src={Bi} alt="Motion" className="pb-6 w-6" />
                    <p className="text-display-xs font-semibold pb-4">
                      Análise de Dados & BI
                    </p>
                    <p className="text-body-md font-normal text-neutral-600">
                      Utilizamos a análise de dados para fornecer insights que auxiliam na tomada de decisões estratégicas.
                    </p>
                  </div>
                  <div className="bg-white p-8 shadow-xl">
                    <img src={Consultancy} alt="Branding" className="pb-6 w-6" />
                    <p className="text-display-xs font-semibold pb-4">
                      Consultoria Digital
                    </p>
                    <p className="text-body-md font-normal text-neutral-600">
                      Ajudamos sua empresa a se adaptar ao cenário digital em constante mudança, implementando estratégias e tecnologias de ponta.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Services;
